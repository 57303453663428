import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, zip} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {isNil} from 'lodash-es';
import {map, switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import {Step} from '../../../app/step-definitions';

export enum TaskDefinition {
  FollowUpBackOffice = 'follow-up-back-office',
  FollowUpAgent = 'follow-up-agent'
}

export interface Variables {
  [key: string]: any;
}

export interface Task {
  id: string;
  processInstanceId: string;
  name: string;
  assignee: number;
  created: Date;
  formKey: TaskDefinition;
  candidateUserIds: number[];
  candidateGroupIds: string[];
  commentCount?: number;
  variables?: Variables;
  permissions: string[];
  taskDefinitionKey: string;
}

export interface TaskResponse {
  tasks: Task[];
  start: number;
  stop: number;
  total: number;
}

export interface CreateProcess {
  processDefinitionKey: string;
  variables: any;
  step?: Step;
}

export interface Process {
  tasks: Task[];
}

export enum TaskOrder {
  TASK_CREATE_TIME_DESC, TASK_CREATE_TIME_ASC
}

export interface FilterModel {
  type?: string;
  skip?: number;
  limit?: number;
  createdBefore?: Date;
  createdAfter?: Date;
  initiator?: number; // user id
  assigned?: boolean;
  candidateGroup?: string;
  candidateUser?: number; // user id
  customerId?: number; // customer id
  assignee?: number; // user id
  orderBy?: TaskOrder;
  sparte?: number;
}

export interface TaskComment {
  id: string;
  message: string;
  time: Date;
  personId: string;
}

export interface TaskEvent {
  time: Date;
  operationType: string;
  personId: string;
  values: string[];
}

@Injectable()
export class TaskService {
  constructor(private http: HttpClient) {
  }

  public static filterToUrlParams(fmodel: FilterModel) {
    let params = new HttpParams();
    if (fmodel) {
      for (const key of Object.keys(fmodel)) {
        let value = fmodel[key];
        if (!isNil(value)) {
          if (key === 'orderBy') {
            value = TaskOrder[value];
          }
          if (value instanceof Number) {
            value = value.toString();
          }
          if (value instanceof Date) {
            if (key === 'createdBefore') {
              value.setUTCHours(23, 59, 59, 999);
            }
            value = value.toISOString();
          }
          if (moment.isMoment(value)) {
            if (key === 'createdBefore') {
              value.set({hour: 23, minute: 59, second: 59, millisecond: 999});
            }
            value = moment(value).toISOString();
          }
          // ignore empty string
          if (value instanceof String && value.length === 0) {
            continue;
          }
          params = params.set(key, value);
        }
      }
    }
    return params;
  }

  private static parsePageableResult(value: string) {
    const xResultPattern = /(\d+)-(\d+)\/(\d+)/g;
    let start = 0;
    let stop = 0;
    let total = 0;
    const match = xResultPattern.exec(value);
    if (match) {
      start = parseInt(match[1], 10);
      stop = parseInt(match[2], 10);
      total = parseInt(match[3], 10);
    }
    return {start, stop, total};
  }

  getTasks(f?: FilterModel): Observable<TaskResponse> {
    const params = TaskService.filterToUrlParams(f);
    return this.http.get<Task[]>('/inbox/api/tasks', {params, observe: 'response'})
      .pipe(
        map((res) => Object.assign({tasks: res.body}, TaskService.parsePageableResult(res.headers.get('x-result-count')))),
        switchMap(response => {
            if (response.tasks.length === 0) {
              return of(response);
            }
            return forkJoin(response.tasks
              .map(task => zip(of(task), this.getTaskVariables(task.id), (t, variables) => {
                t.variables = variables;
                return t;
              })))
              .pipe(
                map(tasks => {
                  response.tasks = tasks;
                  return response;
                }));
          }
        ));
  }

  getTaskVariables(id: string): Observable<Map<string, any>> {
    return this.http.get<Map<string, any>>(`/inbox/api/tasks/${id}/variables`);
  }

  saveTaskVariables(id: string, variables: {}): Observable<void> {
    return this.http.put<void>(`/inbox/api/tasks/${id}/variables`, variables);
  }

  getTask(id: string): Observable<Task> {
    return forkJoin([this.http.get<Task>(`/inbox/api/tasks/${id}`), this.getTaskVariables(id)])
      .pipe(
        map(([task, variables]) => {
          task.variables = variables;
          return task;
        }));
  }

  getComments(id: string): Observable<TaskComment[]> {
    return this.http.get<TaskComment[]>(`/inbox/api/tasks/${id}/comments`);
  }

  getTaskEvents(taskID: string): Observable<TaskEvent[]> {
    return this.http.get<TaskEvent[]>(`/inbox/api/tasks/${taskID}/events`);
  }

  createProcess(create: CreateProcess): Observable<Process> {
    return this.http.post<Process>('/inbox/api/processes', create)
      .pipe(
        switchMap(process => {
            if (process.tasks.length === 0) {
              return of(process);
            }
            return forkJoin(process.tasks
              .map(task => zip(of(task), this.getTaskVariables(task.id), (t, variables) => {
                t.variables = variables;
                return t;
              })))
              .pipe(map(tasks => {
                process.tasks = tasks;
                return process;
              }));
          }
        ));
  }

  updateCandidateUsers(id: string, candidateUsers: Array<number>): Observable<void> {
    return this.http.post<void>(`/inbox/api/tasks/${id}/candidate-users`, candidateUsers);
  }

  updateCandidateGroups(id: string, candidateGroupIDs: Array<string>): Observable<void> {
    return this.http.post<void>(`/inbox/api/tasks/${id}/candidate-groups`, candidateGroupIDs);
  }

  claimTask(id: string): Observable<void> {
    return this.http.post<void>(`/inbox/api/tasks/${id}/claim`, null);
  }

  unclaimTask(id: string, variables: {}): Observable<void> {
    return this.http.post<void>(`/inbox/api/tasks/${id}/unclaim`, variables);
  }

  completeTask(id: string, variables: {}): Observable<Task[]> {
    return this.saveTaskVariables(id, variables)
      .pipe(switchMap(() => this.http.post<Task[]>(`/inbox/api/tasks/${id}/complete`, variables)));
  }

  addComment(id: string, comment: string): Observable<void> {
    if (!comment || comment.length === 0) {
      return of(null);
    }
    return this.http.post<void>(`/inbox/api/tasks/${id}/comments`, comment);
  }

  deleteTask(task: Task): Observable<void> {
    return this.http.delete<void>(`/inbox/api/tasks/${task.id}`);
  }

  resetContract(taskId: string, contractId: number): Observable<void> {
    return this.http.post<void>(`/inbox/api/aza/${taskId}/reset-contract/${contractId}`, null);
  }

  transferCustomer(taskId: string, customerId: string): Observable<any> {
    return this.http.post(`/inbox/api/vorgaenge/${taskId}/verschieben?customer-id=${customerId}`, null);
  }
}



