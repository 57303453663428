<div class="flex gap-6" [formGroup]="contractGroup">
  <div class="flex flex-column gap-2 w-8">

    <div class="flex w-full" *ngIf="!!importedContract">
      <div class="w-4 text-right pr-6 border-box-elem">
        <h3><b>{{contractGroup.get("importQuelle")?.value}}</b></h3>
      </div>
      <div class="flex-auto text-left">
        <h3><b>Ergebnis</b></h3>
      </div>
    </div>

    <div class="flex">
      <app-imported-field
        class="w-4"
        *ngIf="!!importedContract"
        [disabled]="!importedInsurer()"
        [resultFormControl]="insurerControl"
        [importedValue]="importedInsurer()"
        [displayValue]="importedContract?.gesellschaftsname"
      ></app-imported-field>
      <mat-form-field class="flex-auto">
        <mat-label>Gesellschaft</mat-label>
        <mat-select [formControl]="insurerControl" [compareWith]="compareInsurers" required>
          <mat-option>
            <ngx-mat-select-search [formControl]="insurerFilterControl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let i of filteredInsurers | async" [value]="i">
            <span>{{ i.kurzname }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex">
      <app-imported-field
        class="w-4"
        *ngIf="!!importedContract"
        [resultFormControl]="contractGroup.get('tarif')"
        [importedValue]="importedTariff()"
        [disabled]="!importedTariff()"
        [displayValue]="importedContract?.tarifBezeichnung"
      ></app-imported-field>
      <mat-form-field class="flex-auto">
        <mat-label>Tarif</mat-label>
        <mat-select formControlName="tarif" required>
          <mat-optgroup *ngFor="let option of filteredTariffIds | async" [label]="option.group">
            <mat-option *ngFor="let id of option.tariffIds" [value]="id">
              <span>{{ displayTariff(id) }}</span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex align-items-center" *ngIf="isBackoffice">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <div class="flex flex-grow-1 gap-4 justify-content-between align-items-center">
        <mat-form-field class="flex-grow-1">
          <mat-label>Posteingang</mat-label>
          <input matInput [matDatepicker]="posteingangPicker" [min]="contractMinDate" [max]="today"
                 formControlName="posteingang">
          <mat-datepicker-toggle matSuffix [for]="posteingangPicker" tabIndex="-1"></mat-datepicker-toggle>
          <mat-datepicker #posteingangPicker></mat-datepicker>
          <mat-error *ngIf="contractGroup.get('posteingang')?.hasError('matDatepickerMin') ||
          contractGroup.get('posteingang')?.hasError('matDatepickerMax')">{{ getDatePickerErrorMessage('posteingang') }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-grow-1">
          <mat-label>Postausgang</mat-label>
          <input matInput [matDatepicker]="postausgangPicker" formControlName="postausgang" [min]="contractMinDate" [max]="today">
          <mat-datepicker-toggle matSuffix [for]="postausgangPicker" tabIndex="-1"></mat-datepicker-toggle>
          <mat-datepicker #postausgangPicker></mat-datepicker>
          <mat-error *ngIf="contractGroup.get('postausgang')?.hasError('matDatepickerMin') ||
          contractGroup.get('postausgang')?.hasError('matDatepickerMax')">{{ getDatePickerErrorMessage('postausgang') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="flex" *ngIf="antragEmpfaengerTypes && isBackoffice">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>Antragsempfänger</mat-label>
        <mat-select formControlName="antragEmpfaenger" required>
          <mat-option *ngFor="let key of antragEmpfaengerTypesKeys;" [value]="key">
            <span>{{ antragEmpfaengerTypes[key] }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>Antragsdatum</mat-label>
        <input matInput [matDatepicker]="timePicker" formControlName="antragsdatum"
               [min]="contractMinDate" [max]="today">
        <mat-datepicker-toggle matSuffix [for]="timePicker" tabIndex="-1"></mat-datepicker-toggle>
        <mat-datepicker #timePicker></mat-datepicker>
        <mat-error
          *ngIf="contractGroup.get('antragsdatum')?.hasError('matDatepickerMin') ||
          contractGroup.get('antragsdatum')?.hasError('matDatepickerMax')">
          {{ getDatePickerErrorMessage('antragsdatum') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="flex">
      <app-imported-field
        class="w-4"
        *ngIf="!!importedContract"
        [resultFormControl]="contractGroup.get('beginlaufzeit')"
        [importedValue]="importedContract?.beginlaufzeit"
        [displayValue]="importedContract?.beginlaufzeit | date: 'dd.MM.yyyy'"
      ></app-imported-field>
      <mat-form-field class="flex-auto">
        <mat-label>Beginn der Laufzeit</mat-label>
        <input matInput [matDatepicker]="fromPicker" [min]="contractMinDate" [max]="contractMaxDate"
               formControlName="beginlaufzeit" required>
        <mat-datepicker-toggle matSuffix [for]="fromPicker" tabIndex="-1"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
        <mat-error
          *ngIf="contractGroup.get('beginlaufzeit')?.hasError('matDatepickerMin') ||
          contractGroup.get('beginlaufzeit')?.hasError('matDatepickerMax')">{{ getDatePickerErrorMessage('beginlaufzeit') }}
        </mat-error>
        <mat-error *ngIf="contractGroup.get('beginlaufzeit')?.hasError('matDatepickerParse')">Bitte gib ein valides
          Datum ein.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="flex" *ngIf="showLaufzeitende">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>Ende der Laufzeit</mat-label>
        <input matInput [matDatepicker]="unitllPicker" formControlName="laufzeitende">
        <mat-datepicker-toggle matSuffix [for]="unitllPicker" tabIndex="-1"></mat-datepicker-toggle>
        <mat-datepicker #unitllPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex align-items-center" *ngIf="showBeitragzahldauer">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <div class="flex flex-auto flex-wrap gap-4 justify-content-between align-items-center">
        <div class="flex flex-auto gap-4 align-items-center">
          <div class="sub-headline">Endalter der Beitragszahlung</div>
          <mat-form-field class="w-9rem">
            <mat-label>Jahre</mat-label>
            <input matInput type="number" formControlName="endAlter">
          </mat-form-field>
        </div>
        <div class="flex flex-auto gap-4 align-items-center">
          <div class="sub-headline">Beitragszahldauer</div>
          <mat-form-field class="w-9rem">
            <mat-label>Jahre</mat-label>
            <input matInput type="number" formControlName="laufzeitJahre">
          </mat-form-field>
          <mat-form-field class="w-9rem">
            <mat-label>Monate</mat-label>
            <input matInput type="number" formControlName="laufzeitMonate">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="flex">
      <app-imported-field
        class="w-4"
        *ngIf="!!importedContract"
        [resultFormControl]="contractGroup.get('zahlbeitrag')"
        [importedValue]="importedContract?.zahlbeitrag"
        [displayValue]="importedContract?.zahlbeitrag | number:'1.2-2'"
      ></app-imported-field>
      <mat-form-field class="flex-auto">
        <mat-label>Zahlbeitrag</mat-label>
        <app-decimal-input formControlName="zahlbeitrag" required></app-decimal-input>
        <span matTextSuffix>€</span>
      </mat-form-field>
    </div>

    <div class="flex">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <div class="flex flex-auto gap-3 align-items-center">
        <mat-icon color="accent" matTooltip="Tarifbeitrag entspricht berechnetem Wert"
                  *ngIf="!isTarifbeitragCalculationDisabled() && isTarifbeitragSameAsCalculated()">check_circle
        </mat-icon>
        <mat-icon color="warn"
                  matTooltip="Tarifbeitrag entspricht NICHT berechnetem Wert von {{calculateTarifbeitrag() | currency: 'EUR'}}"
                  *ngIf="!isTarifbeitragCalculationDisabled() && !isTarifbeitragSameAsCalculated()">report_problem
        </mat-icon>
        <mat-form-field class="flex-auto">
          <mat-label>Tarifbeitrag</mat-label>
          <app-decimal-input formControlName="tarifbeitrag" required></app-decimal-input>
          <span matTextSuffix>€</span>
        </mat-form-field>
        <button mat-raised-button type="button"
                tabindex="-1"
                matTooltip="Tarifbeitrag aus Zahlbeitrag berrechnen"
                (click)="updateTarifbeitragToCalculatedValue()"
                [disabled]="isTarifbeitragCalculationDisabled()">Berechnen
        </button>
      </div>
    </div>

    <div class="flex">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>Zahlweise</mat-label>
        <mat-select formControlName="zahlweise" required>
          <mat-option *ngFor="let po of paymentOptions;" [value]="po.dbvalue">
            <span>{{ po.uivalue }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <div class="flex flex-auto gap-3 align-items-center">
        <mat-icon color="accent" matTooltip="Bewertungssumme entspricht dem berechnetem Wert"
                  *ngIf="!isBwsCalculationDisabled() && isBwsSameAsCalculated()">check_circle
        </mat-icon>
        <mat-icon color="warn"
                  matTooltip="Bewertungssumme entspricht NICHT dem berechnetem Wert von {{calculatedBws() | currency: 'EUR'}}"
                  *ngIf="!isBwsCalculationDisabled() && !isBwsSameAsCalculated()">report_problem
        </mat-icon>
        <mat-form-field class="flex-auto">
          <mat-label>Bewertungssumme</mat-label>
          <app-decimal-input formControlName="bewertungssumme" placeholder="Wird automatisch berechnet"
                             digitInfo="0.0-0"></app-decimal-input>
          <span matTextSuffix>€</span>
        </mat-form-field>
        <button mat-raised-button type="button"
                tabindex="-1"
                matTooltip="Bewertungssumme berrechnen"
                (click)="updateBwsToCalculatedValue()"
                [disabled]="isBwsCalculationDisabled()">Berechnen
        </button>
      </div>
    </div>

    <div class="flex">
      <app-imported-field
        class="w-4"
        *ngIf="!!importedContract"
        [importedValue]="importedContract?.bemerkung"
        [resultFormControl]="contractGroup.get('bemerkung')"
        [disabled]="true"
      ></app-imported-field>
      <mat-form-field class="flex-auto">
        <mat-label>Bemerkung</mat-label>
        <input matInput formControlName="bemerkung" appTrim>
      </mat-form-field>
    </div>

    <div class="flex">
      <div *ngIf="!!importedContract" class="w-4"></div>
      <div class="flex flex-column flex-auto" *ngIf="versichertePersonen.length > 0">
        <div class="flex">
          Versicherte Personen:
        </div>
        <div class="flex">
          <mat-chip-set>
            <mat-chip *ngFor="let personId of versichertePersonen.value; let i=index" class="chip"
                      matTooltip="{{personId | personName: true | async}} entfernen">
              {{ personId | personName: true | async }}
              <mat-icon matChipRemove (click)="removeInsuredCustomer(i)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div class="flex-auto" *ngIf="versichertePersonen.length === 0">
        <i>Keine versicherten Personen</i>
      </div>
    </div>

    <div class="flex">
      <div class="flex w-4 justify-content-end align-items-center" *ngIf="!!importedContract">
        <div *ngIf="!!importedContract.versichertePersonenDetails" class="imported-versicherte-personen-list">
          <div *ngFor="let person of importedContract.versichertePersonenDetails">
            <span>{{ person.nachname + ', ' + person.vorname + ' (' + (person.geburtsdatum | date:'dd.MM.yyyy') + ')' }}
              <br/></span>
          </div>
        </div>
      </div>
      <tr-user-autocomplete placeholder="Versicherte Person suchen"
                            [mode]="'customer'"
                            [formControl]="insuredCustomerIDControl"
                            class="flex flex-auto">
      </tr-user-autocomplete>
      <app-create-customer (newCustomer)="onNewCustomer($event)"></app-create-customer>
    </div>

    <div class="flex">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>Hauptvertrag</mat-label>
        <mat-select formControlName="hauptvertrag">
          <mat-option [value]="null">Kein Hauptvertrag</mat-option>
          <mat-option *ngFor="let i of possibleMainContracts" [value]="i.id">
            <span>{{ getHauptvertragLabel(i) }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="!!isBackoffice && !!contractGroup.get('hauptvertrag').value">
        <button type="button" color="primary" mat-raised-button (click)="onConvertToMain()">In eigenständigen Vertrag
          umwandeln
        </button>
      </div>
    </div>

    <div class="flex" *ngIf="pkvAntragsqualitaeten && isPkvAntragsqualitaet && isBackoffice">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>PKV-Antragsqualitaet</mat-label>
        <mat-select formControlName="pkvAntragsqualitaet">
          <mat-option *ngFor="let key of pkvAntragsqualitaetenKeys;" [value]="key">
            <span>{{ pkvAntragsqualitaeten[key] }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex" *ngIf="buAntragVariantenTypes && isBUContract && isBackoffice">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>BU Antrag Variante</mat-label>
        <mat-select formControlName="buAntragVariante">
          <mat-option *ngFor="let key of buAntragVariantenTypesKeys;" [value]="key">
            <span>{{ buAntragVariantenTypes[key] }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex" *ngIf="existingContracts.length">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <app-multi-select-superseded-contract class="flex flex-auto"
                                            [tariffs]="tariffs"
                                            [allPriorContracts]="existingContracts"
                                            [initialList]="contractGroup.get('abgeloesteVertraege').value"
                                            (resultList)="setSupersededContracts($event)">
      </app-multi-select-superseded-contract>
    </div>

    <div class="flex" *ngIf="showIdentifikationsmerkmal">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>Identifikationsmerkmal</mat-label>
        <mat-select formControlName="identifikationsmerkmal">
          <mat-option *ngFor="let i of customer.identifikationsmerkmale" [value]="i.id">
            <span>{{ displayIdentityFeature(i) }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <tr-user-autocomplete placeholder="Vermittler" class="flex flex-auto"
                            [mode]="'agent'"
                            [additionalFilter]="only34dFilter"
                            matTooltip="Abschlussvermittler"
                            formControlName="consultant" required>
      </tr-user-autocomplete>
    </div>

    <div class="flex" [class]="!isBackoffice ? 'highlight' : ''">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <tr-user-autocomplete placeholder="Provisionsempfänger" class="flex flex-auto"
                            [mode]="'agent'"
                            [additionalFilter]="includeConsultantFilter"
                            formControlName="vp" required>
      </tr-user-autocomplete>
    </div>

    <div class="flex" *ngIf="antragartTypes && !importedContract">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <mat-form-field class="flex-auto">
        <mat-label>Antragart</mat-label>
        <mat-select formControlName="antragart" required>
          <mat-option>Nicht Ausgewählt</mat-option>
          <mat-option *ngFor="let key of antragartTypesKeys;" [value]="key">
            <span>{{ antragartTypes[key] }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex" *ngIf="!importedContract">
      <div class="w-4" *ngIf="!!importedContract"></div>
      <div class="flex">
        <mat-checkbox formControlName="fremdvertrag" class="w-6">Fremdvertrag</mat-checkbox>
      </div>
    </div>

  </div>
  <app-contract-documents class="w-4" [documentsArray]="contractGroup.controls['dokumente']"></app-contract-documents>
</div>

