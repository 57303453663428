import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {Task, TaskDefinition, TaskService} from '../../../shared/common/services/task.service';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {map} from 'rxjs/operators';
import {TaskDetailsComponent} from '../task-details-dialog/task-details.component';
import {TaskAssignComponent} from '../task-assign-dialog/task-assign.component';
import {StartWizardAction} from '../../actions/wizard.actions';
import {ViewHistoryComponent} from '../history-dialog/view-history.component';
import {ConfirmDeleteTaskAction} from '../../actions/inbox';
import {TransferCustomerComponent} from '../transfer-customer/transfer-customer.component';
import {isNil} from 'lodash-es';
import {KeycloakTokenService} from '@taures/angular-commons';
import {Person, PersonService} from '../../services/person.service';
import {GroupsService, UserGroup} from '../../../groups/services/groups.service';
import {ProgressSpinnerService} from '../progress-spinner-dialog/progress-spinner-service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-inbox-task-card',
  templateUrl: './inbox-task-card.component.html',
  styleUrls: ['./inbox-task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class InboxTaskCardComponent {
  customerName: Observable<string>;
  candidateUsers: Observable<Person[]>;
  candidateGroups: Observable<UserGroup[]>;
  isAdmin = this.keycloak.hasRole('admin', environment.roleResourceId);
  @Input()
  currentUserId: number;
  taskDefinitionEnum = TaskDefinition;

  @Output()
  claimTask: EventEmitter<Task> = new EventEmitter();
  @Output()
  unclaimTask: EventEmitter<Task> = new EventEmitter();
  @Output()
  transferCustomer: EventEmitter<void> = new EventEmitter();

  constructor(private dialog: MatDialog, private store: Store<fromRoot.State>,
              readonly keycloak: KeycloakTokenService,
              readonly groupService: GroupsService,
              readonly personService: PersonService,
              readonly taskService: TaskService,
              private progressSpinnerService: ProgressSpinnerService) {
  }

  private currentTask: Task;

  get task() {
    return this.currentTask;
  }

  @Input()
  set task(value: Task | null) {
    this.currentTask = value;
    if (this.task) {
      if (this.task.variables) {
        const customer = this.task.variables.customer as Person;

        let customerObservable: Observable<Person>;
        // only take the name of the task variables customer, if both first and last name is set
        if (customer && customer.vorname && customer.nachname) {
          customerObservable = of(customer);
        } else {
          const customerId = this.task.variables.customerId;
          if (customerId) {
            customerObservable = this.personService.getPerson(customerId);
          }
        }

        this.customerName = customerObservable.pipe(map(c => {
          const firstname = c.vorname;
          const lastname = c.nachname;
          const importedCustomer = this.task.variables.importedCustomer;
          if (importedCustomer && importedCustomer.vorname && importedCustomer.nachname &&
            (importedCustomer.vorname !== firstname || importedCustomer.nachname !== lastname)) {
            return `${lastname}, ${firstname} (${importedCustomer.nachname}, ${importedCustomer.vorname})`;
          } else {
            return `${lastname}, ${firstname}`;
          }
        }));
      }

      if (this.task.candidateUserIds) {
        const observables: Observable<Person>[] = [];
        this.task.candidateUserIds.forEach(userID => {
          observables.push(
            this.personService.getPerson(userID));
        });
        this.candidateUsers = forkJoin(observables);
      }

      if (this.task.candidateGroupIds) {
        this.candidateGroups = this.groupService.getGroups()
          .pipe(
            map(allGroups => {
              const result = [];
              this.task.candidateGroupIds.forEach(
                id => {
                  if (!result.find(e => e && e.id === id)) {
                    const group = allGroups.find(g => g && g.id === id);
                    if (group) {
                      result.push(group);
                    }
                  }
                }
              );
              return result;
            }));
      }
    }
  }

  get isAssignedToCurrentUser(): boolean {
    return this.currentUserId === this.task.assignee;
  }

  openTaskDetails() {
    this.dialog.open(TaskDetailsComponent, {data: this.task, width: '600px'});
  }

  openAssignTask() {
    this.dialog.open(TaskAssignComponent, {data: this.task, width: '600px'});
  }

  openTransferCustomer() {
    this.dialog.open(TransferCustomerComponent, {width: '448px'})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.progressSpinnerService.show();
          this.taskService.transferCustomer(this.task.id, result.variables.customerId)
            .subscribe(() => {
              this.progressSpinnerService.hide();
              this.transferCustomer.emit();
            });
        }
      });
  }

  onClaim() {
    this.claimTask.emit(this.task);
  }

  onUnclaim() {
    this.unclaimTask.emit(this.task);
  }

  onEdit() {
    this.store.dispatch(new StartWizardAction({task: this.task}));
  }

  onViewComment() {
    this.dialog.open(ViewHistoryComponent, {data: this.task, width: '600px'});
  }

  onDeleteTask() {
    this.store.dispatch(new ConfirmDeleteTaskAction(this.task));
  }

  formatUsersList(users: {nachname: string, vorname: string }[] | null) {
    return users != null ? users.map(p => `${p.nachname}, ${p.vorname}`
    ).join('; ') : '';
  }

  formatGroupsList(groups: UserGroup[]) {
    return groups ? groups.map(g => g.bezeichnung).join(', ') : '';
  }

  canBeTransfered() {
    return this.task.formKey === this.taskDefinitionEnum.FollowUpAgent && !isNil(this.task.variables.originalImportedCustomer);
  }

  canDelete() {
    return this.task.permissions.includes('DELETE');
  }
}
