import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {Observable, of, Subject} from 'rxjs';

import {maxBirthday, minBirthday} from '../../../../shared/form-helper';
import {LANGUAGE_CODES} from '../../../services/language-codes';
import {TauresValidators} from '../../../taures-validators';
import {Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService} from '../../../services/app-options.service';
import {COUNTRY_CODES} from '../../../services/country-codes';
import {Person} from "../../../services/person.service";
import {UniquePersonValidatorService} from "../../../services/unique-person-validator.service";

@Component({
  selector: 'app-basic-data-comparison',
  templateUrl: 'basic-data-comparison.component.html',
  styleUrls: ['basic-data-comparison.component.scss']
})
export class BasicDataComparisonComponent implements OnInit, OnDestroy {
  @Input()
  customer: Person;

  @Input()
  variables: UntypedFormGroup;

  educationTypes: Observable<{ [key: string]: string }>;
  sortedCountries: { code: string, name: string }[] = COUNTRY_CODES;
  sortedLanguages: { code: string, name: string }[] = LANGUAGE_CODES;
  @ViewChild('countryAutocomplete', { static: true }) countryAutocomplete: MatAutocomplete;
  @ViewChild('langAutocomplete', { static: true }) langAutocomplete: MatAutocomplete;

  readonly importedRowWidths = [1, 2, 5, 3];
  readonly regularRowWidths = [2, 0, 5, 4];
  readonly salutations = ['Herr', 'Frau', 'Firma'];
  readonly titles = ['', 'Dr.', 'Prof.', 'Prof. Dr.'];
  readonly minBirthday: Date = minBirthday();
  readonly maxBirthday: Date = maxBirthday();

  private destroy = new Subject();

  constructor(readonly options: AppOptionsService,
              readonly uniquePersonValidator: UniquePersonValidatorService,
              readonly changeDetector: ChangeDetectorRef,
              readonly ngZone: NgZone) {
    this.educationTypes = of(this.options.educationTypes);
  }

  get rowWidths(): Array<number> {
    return (this.hasImportedCustomer()) ? this.importedRowWidths : this.regularRowWidths;
  }

  get duplicatePersonId(): number {
    return this.hasDuplicatePerson() ? this.variables.get('customer').errors.uniquePerson : null;
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables) {
    const customer = variables.customer || {};
    const importedCustomer = variables.importedCustomer || {};
    return fb.group({
      duplicatePerson: [variables.duplicatePerson, [TauresValidators.requiredFalse]],
      customer: fb.group({
        id: [customer.id, [Validators.required]],
        anrede: [customer.anrede, [Validators.required]],
        titel: customer.titel,
        vorname: [customer.vorname, [Validators.required, Validators.maxLength(30)]],
        nachname: [customer.nachname, [Validators.required, Validators.maxLength(50)]],
        geburtsname: [customer.geburtsname, [Validators.maxLength(50)]],
        bildungsabschluss: customer.bildungsabschluss,
        geburtsdatum: [customer.geburtsdatum ? new Date(customer.geburtsdatum) : null, [Validators.required]],
        geburtsort: [customer.geburtsort, [Validators.maxLength(150)]],
        geburtsland: customer.geburtsland,
        nationalitaet: [customer.nationalitaet, [Validators.required]],
        sprache: customer.sprache
      }),
      importedCustomer: fb.group({
        quelle: variables.importQuelle,
        geburtsname: importedCustomer.geburtsname,
        geburtsort: importedCustomer.geburtsort,
        geburtsdatum: importedCustomer.geburtsdatum,
        titel: importedCustomer.titel,
        vorname: importedCustomer.vorname,
        nachname: importedCustomer.nachname,
      })
    });
  }

  ngOnInit() {
    this.variables.get('customer').setAsyncValidators([this.uniquePersonValidator.validator(this.customer.id)]);
    // copy the duplicate person validation result into a static field in order to have the validation result
    // in the right bar
    this.variables.get('customer').statusChanges.subscribe(status => {
      this.variables.get('duplicatePerson').setValue(this.hasDuplicatePerson());
      this.changeDetector.markForCheck();
    });
    // trigger validation
    setTimeout(() => this.ngZone.run(() => this.variables.get('customer').updateValueAndValidity()));
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  public hasImportedCustomer(): boolean {
    if (!this.variables) {
      return false;
    }

    return (
      !!this.variables.get('importedCustomer.titel').value ||
      !!this.variables.get('importedCustomer.vorname').value ||
      !!this.variables.get('importedCustomer.nachname').value
    );
  }

  public hasDuplicatePerson(): boolean {
    return this.variables.get('customer').errors && this.variables.get('customer').errors.uniquePerson !== this.customer.id;
  }

  educationOptionsData(educationTypes: { [key: string]: string }): string[] {
    const result = !!educationTypes ? Object.keys(educationTypes) : [];
    result.unshift('');
    return result;
  }

  educationOptionsDisplay(educationTypes: { [key: string]: string }): string[] {
    const result = !!educationTypes ? Object.values(educationTypes) : [];
    result.unshift('');
    return result;
  }

  countryOfCustomer(customer: Person): string {
    return customer && customer.nationalitaet ? COUNTRY_CODES
      .find(c => c.code.toLowerCase() === customer.nationalitaet.toLowerCase()).name : '';
  }

  languageOfCustomer(customer: Person): string {
    return customer && customer.sprache ? LANGUAGE_CODES
      .find(c => c.code.toLowerCase() === customer.sprache.toLowerCase()).name : '';
  }

  educationOfCustomer(customer: Person, educationTypes: { [key: string]: string }): string {
    if (customer && customer.bildungsabschluss && educationTypes) {
      return educationTypes[customer.bildungsabschluss];
    }
    return '';
  }
}

