import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNil} from 'lodash-es';
import * as moment from 'moment';

@Component({
  selector: 'app-basic-data-form-field',
  templateUrl: 'basic-data-form-field.component.html',
  styleUrls: ['basic-data-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicDataFormFieldComponent implements OnInit, OnDestroy {

  @Input() resultFormControl: UntypedFormControl;
  @Input() title: string;
  @Input() rowWidths: Array<number>;
  @Input() options: Array<string>;
  @Input() hasImportedCustomer: boolean;
  @Input()
  serverValueFormatted: string;
  @Input()
  importedValue: any;
  @Input()
  importedValueFormatted: string;
  rowClass = '';
  titleClasses = 'list-item list-item-left';
  private unsubscribe = new Subject();
  private origServerValue: any;

  constructor(readonly changeDetector: ChangeDetectorRef) {
  }

  get serverValue() {
    return this.origServerValue;
  }

  @Input()
  set serverValue(value: any) {
    this.origServerValue = value;
    this.toggleComparisonWarning(this.resultFormControl.value);
  }

  private static showWarningOnUnmatch(serverVal: any, formVal: any, ignoreEmpty = false): boolean {
    if (!isNil(serverVal) && !isNil(formVal)) {
      if (serverVal instanceof Date || moment.isMoment(serverVal) || formVal instanceof Date || moment.isMoment(formVal)) {
        return !moment(serverVal).utc().isSame(formVal, 'day');
      }
      return serverVal !== formVal;
    }
    return !ignoreEmpty ? !serverVal !== !formVal : false;
  }

  ngOnInit() {
    this.toggleValid(this.resultFormControl.valid, this.resultFormControl.disabled);
    this.resultFormControl
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.toggleComparisonWarning(value));

    this.resultFormControl.statusChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.toggleValid(this.resultFormControl.valid, this.resultFormControl.disabled);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  assignImportedValue() {
    this.resultFormControl.setValue(this.importedValue);
  }

  assignServerValue() {
    this.resultFormControl.setValue(this.serverValue);
  }

  private toggleComparisonWarning(value) {
    if (!value && BasicDataFormFieldComponent.showWarningOnUnmatch(this.serverValue, this.importedValue, true)) {
      this.rowClass = 'row-discrepancy-error';
    } else if (BasicDataFormFieldComponent.showWarningOnUnmatch(this.serverValue, value)) {
      this.rowClass = 'row-discrepancy-warning';
    } else {
      this.rowClass = '';
    }
    this.changeDetector.markForCheck();
  }

  private toggleValid(valid, disabled) {
    if (valid || disabled) {
      this.titleClasses = 'list-item list-item-left';
    } else {
      this.titleClasses = 'list-item list-item-left invalid';
    }
    this.changeDetector.markForCheck();
  }
}
