import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-imported-field',
  templateUrl: 'imported-field.component.html',
  styleUrls: ['imported-field.component.scss']
})
export class ImportedFieldComponent {

  @Input() titel: string;
  @Input() disabled: boolean;
  @Input() importedValue: any;
  @Input() resultFormControl: UntypedFormControl;
  private displayableValue: string;

  get displayValue(): string {
    return !!this.displayableValue ? this.displayableValue : (!!this.importedValue ? this.importedValue : '');
  }

  @Input()
  set displayValue(value: string) {
    this.displayableValue = value;
  }

  assignImportedValue() {
    if (!!this.importedValue && this.resultFormControl) {
      this.resultFormControl.setValue(this.importedValue);
    }
  }
}
