import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, of} from 'rxjs';

import {Tariff, TariffService} from '../../services/tariff.service';
import {Task} from '../../../shared/common/services/task.service';
import {map} from 'rxjs/operators';
import {Tariffs} from '../../tariffs';
import {Contract, Person, PersonService} from '../../services/person.service';

@Component({
  selector: 'app-task-details',
  templateUrl: 'task-details.component.html',
  styleUrls: ['task-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDetailsComponent {
  customer: Observable<Person>;
  customerName: Observable<string>;
  newContracts: Observable<Contract[]>;
  tariffs: Observable<Tariff[]>;

  constructor(@Inject(MAT_DIALOG_DATA) readonly task: Task,
              readonly dialogRef: MatDialogRef<TaskDetailsComponent>,
              readonly tariffService: TariffService,
              readonly personService: PersonService) {

    this.tariffs = of(this.tariffService.tariffs);
    if (this.task) {
      if (this.task.variables) {
        // only request the customer if we not already have her in the variables data
        const customer = this.task.variables.customer as Person;
        const customerId = this.task.variables.customerId;
        if (customer) {
          this.customer = of(customer);
        } else {
          if (customerId) {
            this.customer = this.personService.getPerson(customerId);
          }
        }

        // only take the name of the task variables customer, if both first and last name is set
        let customerNameObservable: Observable<Person>;
        if (customer && customer.vorname && customer.nachname) {
          customerNameObservable = of(customer);
        } else {
          customerNameObservable = this.personService.getPerson(customerId);
        }

        this.customerName = customerNameObservable.pipe(map(c => {
          const firstname = c.vorname;
          const lastname = c.nachname;
          const importedCustomer = this.task.variables.importedCustomer;
          if (importedCustomer && importedCustomer.vorname && importedCustomer.nachname &&
            (importedCustomer.vorname !== firstname || importedCustomer.nachname !== lastname)) {
            return `${lastname}, ${firstname} (${importedCustomer.nachname}, ${importedCustomer.vorname})`;
          } else {
            return `${lastname}, ${firstname}`;
          }
        }));


        this.newContracts = this.customer.pipe(
          map(c => c.vertraege.filter(contract => contract.id < 0)));

      }
    }
  }

  getInsurerName(c: Contract, tariffs: Tariff[]): string {
    let insurerName = Tariffs.insurerName(tariffs, c.tarif);
    if (!insurerName) {
      insurerName = (c as any).gesellschaftsname;
    }
    return insurerName;
  }

  getSparteName(c: Contract, tariffs: Tariff[]): string {
    let sparte = Tariffs.sparteName(tariffs, c.tarif);
    if (!sparte) {
      sparte = (c as any).sparte;
    }
    return sparte;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
