import {BrowserModule} from '@angular/platform-browser';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InboxOverviewComponent} from './components/inbox-overview/inbox-overview.component';
import {AppRoutingModule} from './app-routing.module';
import {FilterComponent} from './components/filter-component/filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {FilterModelResolver} from './services/task/filter-model-resolver.service';
import {TaskDetailsComponent} from './components/task-details-dialog/task-details.component';
import {CreateProcessComponent} from './components/create-process-dialog/create-process.component';
import {BasicDataComparisonComponent} from './components/data-comparison/customer-basic-data/basic-data-comparison.component';
import {effects} from './effects';
import {GermanPaginatorIntl} from './german-paginator-intl';
import {TaskWizardContainerComponent} from './components/task-wizard-container/task-wizard-container.component';
import {CancelTaskWizardComponent} from './components/cancel-task-wizard/cancel-task-wizard.component';
import {TaskWizardGuardService} from './services/task/task-wizard-guard.service';
import {HttpClientModule} from '@angular/common/http';
import {CustomerContactDataComparisonComponent} from './components/data-comparison/customer-contact-data/contact-data-comparison.component';
import {ContactComponent} from './components/data-comparison/customer-contact-data/contact/contact.component';
import {SingleAddresseFullComponent} from './components/data-comparison/customer-adresses/single-adresse-full/single-addresse-full.component';
import {ContactAddressComponent} from './components/data-comparison/customer-adresses/contact-address.component';
import {CustomerBankComponent} from './components/data-comparison/customer-bank/customer-bank.component';
import {SingleBankComponent} from './components/data-comparison/customer-bank/single-bank/single-bank.component';
import {TariffService} from './services/tariff.service';
import {ContactTypeGroupPipe} from './components/data-comparison/customer-contact-data/contact-type.pipe';
import {CustomerContractComponent} from './components/data-comparison/customer-contract/customer-contract.component';
import {SingleContractComponent} from './components/data-comparison/customer-contract/single-contract-full/single-contract-full.component';
import {ContractDocumentsComponent} from './components/data-comparison/customer-contract/contract-documents/contract-documents.component';
import {SingleContractSmallComponent} from './components/data-comparison/customer-contract/single-contract-small/single-contract-small.component';
import {DocumentFormComponent} from './components/document-form/document-form.component';
import {DocumentFileInputComponent} from './components/document-file-input/document-file-input.component';
import {DocumentService} from './services/document.service';
import {CustomerIdentityComponent} from './components/data-comparison/customer-identity/customer-identity.component';
import {SingleIdentityComponent} from './components/data-comparison/customer-identity/single-identity/single-identity.component';
import {CustomerContractSingleComponent} from './components/data-comparison/customer-contract-single/customer-contract-single.component';
import {CanDeactivateGuard} from './services/can-deactivate-guard.service';
import {ConfirmBackComponent} from './components/confirm-back/confirm-back.component';
import {MultiSelectSupersededComponent} from './components/data-comparison/customer-contract/multi-select-supersed/multi-select-superseded.component';
import {SurplusComponent} from './components/data-comparison/customer-surplus/surplus.component';
import {SingleSurplusComponent} from './components/data-comparison/customer-surplus/single-surplus/single-surplus.component';
import {TaskAssignComponent} from './components/task-assign-dialog/task-assign.component';
import {ReworkDialogComponent} from './components/data-comparison/task-reworks/rework-dialog/rework-dialog.component';

import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {DocumentsOverviewComponent} from './components/data-comparison/documents-overview/documents-overview.component';
import {TaskReworksComponent} from './components/data-comparison/task-reworks/task-reworks.component';
import {ReworkFormComponent} from './components/data-comparison/task-reworks/rework-form/rework-form.component';
import {BrokerContractService} from './services/broker-contract.service';
import {BrokerContractComponent} from './components/data-comparison/customer-broker-contract/broker-contract.component';
import {BrokerContractDocumentFormComponent} from './components/data-comparison/customer-broker-contract/broker-contract-document-form/broker-contract-document-form.component';
import {SingleEmploymentComponent} from './components/data-comparison/customer-employments/single-employment/single-employment.component';
import {CustomerEmploymentsComponent} from './components/data-comparison/customer-employments/customer-employments.component';
import {CustomerMaritalStatusComponent} from './components/data-comparison/customer-marital-status/customer-marital-status.component';
import {SingleMaritalStatusComponent} from './components/data-comparison/customer-marital-status/single-marital-status/single-marital-status.component';
import {ContractDocumentsDescriptionComponent} from './components/data-comparison/documents-overview/documents-description/contract-documents-description.component';

import {SingleHistoryItemComponent} from './components/history-dialog/single-history-item/single-history-item.component';
import {HistoryItemService} from './services/history-item.service';
import {ViewHistoryComponent} from './components/history-dialog/view-history.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {CommonModule} from '../shared/common/common.module';
import {BasicDataFormFieldComponent} from './components/data-comparison/customer-basic-data/basic-data-form-field/basic-data-form-field.component';
import {ImportedFieldComponent} from './components/data-comparison/customer-contract/imported-field/imported-field.component';
import {ProgressSpinnerDialogComponent} from './components/progress-spinner-dialog/progress-spinner-dialog.component';
import {ProgressSpinnerService} from './components/progress-spinner-dialog/progress-spinner-service';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule} from '@angular/material-moment-adapter';
import {InboxTaskCardComponent} from './components/inbox-task-card/inbox-task-card.component';
import {FollowUpAgentTaskDetailsComponent} from './components/follow-up-agent-task-details/follow-up-agent-task-details.component';
import {FollowUpBackofficeDetailsComponent} from './components/follow-up-backoffice-details/follow-up-backoffice-details.component';
import {AppInitService, AppInitServiceFactory} from './services/app-init.service';
import {WizardCompleteSuccessComponent} from './components/wizard-complete-success/wizard-complete-success.component';
import {TaskStepsControlComponent} from './components/task-steps-control/task-steps-control.component';
import {WizardFormService} from './services/wizard-form.service';
import {PdfPreviewComponent} from './components/pdf-preview/pdf-preview.component';
import {ReworksService} from './services/reworks.service';
import {ReworkReadonlyComponent} from './components/data-comparison/task-reworks/rework-readonly/rework-readonly.component';
import {IbanService} from './services/iban.service';
import {BankChooserComponent} from './components/bank-chooser/bank-chooser.component';
import {MessageDialogComponent} from './components/message-dialog/message-dialog.component';
import {TransferCustomerComponent} from './components/transfer-customer/transfer-customer.component';
import {APP_DATE_FORMAT} from '../shared/common/app-date-format';
import {TaxInfosComponent} from './components/data-comparison/tax-infos/tax-infos.component';
import {MatStepperModule} from '@angular/material/stepper';
import {AzaStartComponent} from './components/aza-start/aza-start.component';
import {DocumentsDropzoneComponent} from './components/data-comparison/documents-overview/documents-dropzone/documents-dropzone.component';
import {DocumentCardComponent} from './components/data-comparison/documents-overview/document-card/document-card.component';
import {DataComparisonCardComponent} from './components/data-comparison-card/data-comparison-card.component';
import {BrokerContractOptionComponent} from './components/data-comparison/customer-broker-contract/broker-contract-option/broker-contract-option.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DocumentPreviewComponent} from './components/data-comparison/documents-overview/document-preview/document-preview.component';
import {PdfPreviewDialogComponent} from './components/pdf-preview-dialog/pdf-preview-dialog.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {OAuthModule} from 'angular-oauth2-oidc';
import {
  AngularCommonsModule,
  LogoutService,
  PersonsModule as CommonsPersonsModule,
  TauresMenueModule
} from '@taures/angular-commons';
import {OauthLogoutService} from './services/oauth-logout.service';
import {CustomerRelationshipsComponent} from './components/data-comparison/customer-relationships/customer-relationships.component';
import {SingleRelationshipComponent} from './components/data-comparison/customer-relationships/single-relationship/single-relationship.component';
import {SelectEmployerComponent} from './components/select-employer/select-employer.component';
import {CreateCustomerDialogComponent} from './components/create-customer-dialog/create-customer-dialog.component';
import {CreateCustomerComponent} from './components/create-customer/create-customer.component';
import {CreateEmployerComponent} from './components/create-employer-dialog/create-employer.component';
import {NotDeletedPipe} from './not-deleted.pipe';
import {AppOptionsService} from './services/app-options.service';
import {PlzLookupService} from './services/plz-lookup.service';
import {CustomerService} from './services/customer.service';
import {GroupsService} from '../groups/services/groups.service';
import {DocumentPreviewFormComponent} from './components/document-preview-form/document-preview-form.component';
import {
  AenderungsauftragDialogComponent
} from "./components/aenderungsauftrag-dialog/aenderungsauftrag-dialog.component";
import {PersonService} from "./services/person.service";

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent,
    MultiSelectSupersededComponent,
    InboxOverviewComponent,
    FilterComponent,
    TaskDetailsComponent,
    TaskAssignComponent,
    ViewHistoryComponent,
    CreateProcessComponent,
    BasicDataComparisonComponent,
    TaskWizardContainerComponent,
    CancelTaskWizardComponent,
    BasicDataComparisonComponent,
    SingleHistoryItemComponent,
    CustomerContactDataComparisonComponent,
    SingleAddresseFullComponent,
    ContactAddressComponent,
    ContactComponent,
    CustomerBankComponent,
    SingleBankComponent,
    ContactTypeGroupPipe,
    CustomerMaritalStatusComponent,
    SingleMaritalStatusComponent,
    CustomerContractComponent,
    ImportedFieldComponent,
    SingleContractComponent,
    SingleContractSmallComponent,
    ContractDocumentsComponent,
    DocumentFormComponent,
    DocumentFileInputComponent,
    CustomerIdentityComponent,
    SingleIdentityComponent,
    SingleEmploymentComponent,
    CustomerEmploymentsComponent,
    CustomerContractSingleComponent,
    ConfirmBackComponent,
    SurplusComponent,
    ReworkDialogComponent,
    SingleSurplusComponent,
    DocumentsOverviewComponent,
    ContractDocumentsDescriptionComponent,
    ReworkFormComponent,
    TaskReworksComponent,
    BrokerContractComponent,
    BrokerContractDocumentFormComponent,
    ConfirmDialogComponent,
    MessageDialogComponent,
    BasicDataFormFieldComponent,
    ProgressSpinnerDialogComponent,
    InboxTaskCardComponent,
    FollowUpAgentTaskDetailsComponent,
    FollowUpBackofficeDetailsComponent,
    WizardCompleteSuccessComponent,
    TaskStepsControlComponent,
    PdfPreviewComponent,
    ReworkReadonlyComponent,
    BankChooserComponent,
    TransferCustomerComponent,
    TaxInfosComponent,
    AzaStartComponent,
    DocumentsDropzoneComponent,
    DocumentCardComponent,
    DataComparisonCardComponent,
    BrokerContractOptionComponent,
    DocumentPreviewComponent,
    PdfPreviewDialogComponent,
    CustomerRelationshipsComponent,
    SingleRelationshipComponent,
    SelectEmployerComponent,
    CreateEmployerComponent,
    CreateCustomerDialogComponent,
    CreateCustomerComponent,
    NotDeletedPipe,
    DocumentPreviewFormComponent,
    AenderungsauftragDialogComponent
  ],
  imports: [
    AngularCommonModule,
    BrowserModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatCardModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTabsModule,
    MomentDateModule,
    MatTableModule,
    MatStepperModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    environment.imports,
    EffectsModule.forRoot(effects),
    OAuthModule.forRoot({resourceServer: {sendAccessToken: true}}),
    AngularCommonsModule,
    TauresMenueModule,
    CommonsPersonsModule,
    CommonModule,
    AppRoutingModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    ProgressSpinnerService,
    TaskWizardGuardService,
    CanDeactivateGuard,
    HistoryItemService,
    FilterModelResolver,
    BrokerContractService,
    TariffService,
    DocumentService,
    AppInitService,
    WizardFormService,
    AppOptionsService,
    PlzLookupService,
    CustomerService,
    GroupsService,
    PersonService,
    {provide: LogoutService, useClass: OauthLogoutService},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMAT},
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitServiceFactory,
      multi: true,
      deps: [AppInitService]
    },
    {
      provide: MatPaginatorIntl,
      useClass: GermanPaginatorIntl
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {disableClose: true, hasBackdrop: true}},
    ReworksService,
    IbanService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
