<div [formGroup]="variables">
  <div class="flex flex-column gap-2" formGroupName="customer">

    <app-basic-data-form-field
      [title]="'Steueridentifikationsnummer (Steuer-ID)'"
      [resultFormControl]="variables.get('customer.steuerId')"
      [serverValue]="(customer)?.steuerId"
      [rowWidths]="rowWidths"
      [hasImportedCustomer]="hasImportedCustomer()">
      <mat-form-field class="w-full">
        <input matInput type="text" formControlName="steuerId" appTrim>
        <mat-error *ngIf="variables.get('customer.steuerId').hasError('steuerId')">Keine gültige Steueridentifikationsnummer</mat-error>
      </mat-form-field>
    </app-basic-data-form-field>

    <div formGroupName="kunde">
      <app-basic-data-form-field
        [title]="'Steuerklasse'"
        [resultFormControl]="variables.get('customer.kunde.steuerklasse')"
        [serverValue]="customer?.kunde?.steuerklasse"
        [rowWidths]="rowWidths"
        [hasImportedCustomer]="hasImportedCustomer()">
        <mat-form-field class="w-full">
          <mat-select formControlName="steuerklasse">
            <mat-option></mat-option>
            <mat-option *ngFor="let key of steuerklassenKeys;" [value]="steuerklassen[key]">
              <span>{{ steuerklassen[key] }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-basic-data-form-field>

      <app-basic-data-form-field
        [title]="'Steuerlich ansässig in'"
        [resultFormControl]="variables.get('customer.kunde.steuerlichAnsaessigIn')"
        [serverValue]="customer?.kunde?.steuerlichAnsaessigIn"
        [serverValueFormatted]="steuerlichAnsaessigInOfCustomer(customer)"
        [rowWidths]="rowWidths"
        [hasImportedCustomer]="hasImportedCustomer()">
        <mat-form-field class="w-full">
          <mat-select formControlName="steuerlichAnsaessigIn">
            <mat-option *ngFor="let country of sortedCountries" [value]="country.code">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-basic-data-form-field>

      <app-basic-data-form-field
        [title]="'Getrennt veranlagt'"
        [resultFormControl]="variables.get('customer.kunde.getrenntVeranlagt')"
        [serverValue]="customer?.kunde?.getrenntVeranlagt"
        [serverValueFormatted]="customer?.kunde?.getrenntVeranlagt ? 'Ja' : 'Nein'"
        [rowWidths]="rowWidths"
        [hasImportedCustomer]="hasImportedCustomer()">
        <mat-checkbox formControlName="getrenntVeranlagt">
        </mat-checkbox>
      </app-basic-data-form-field>

      <app-basic-data-form-field
        [title]="'Sozialversicherungsnummer'"
        [resultFormControl]="variables.get('customer.kunde.sozialversicherungsnummer')"
        [serverValue]="customer?.kunde?.sozialversicherungsnummer"
        [rowWidths]="rowWidths"
        [hasImportedCustomer]="hasImportedCustomer()">
        <mat-form-field class="w-full">
          <input matInput type="text" formControlName="sozialversicherungsnummer" maxlength="12" appTrim>
        </mat-form-field>
      </app-basic-data-form-field>

      <app-basic-data-form-field
        [title]="'Güterstand'"
        [resultFormControl]="variables.get('customer.kunde.gueterstand')"
        [serverValue]="customer?.kunde?.gueterstand"
        [serverValueFormatted]="gueterstaende[customer?.kunde?.gueterstand]"
        [rowWidths]="rowWidths"
        [hasImportedCustomer]="hasImportedCustomer()">
        <mat-form-field class="w-full">
          <mat-select formControlName="gueterstand">
            <mat-option *ngFor="let key of gueterstandeKeys;" [value]="key">
              <span>{{ gueterstaende[key] }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-basic-data-form-field>
    </div>

    <app-basic-data-form-field
      [title]="'Kirchensteuerpflichtig'"
      [resultFormControl]="variables.get('customer.kirchensteuerpflichtig')"
      [serverValue]="(customer)?.kirchensteuerpflichtig"
      [serverValueFormatted]="customer?.kirchensteuerpflichtig ? 'Ja' : 'Nein'"
      [rowWidths]="rowWidths"
      [hasImportedCustomer]="hasImportedCustomer()">
      <mat-checkbox formControlName="kirchensteuerpflichtig">
      </mat-checkbox>
    </app-basic-data-form-field>
  </div>
</div>
